<template>
  <div v-if="!isPageVisible">
    <b-card no-body style="height: 150px" class="d-flex justify-content-center align-items-center">
      <h3>Resource Not Available</h3>
    </b-card>
  </div>
  <div v-else-if="user_type === 'agent'">
    <b-card no-body style="height: 150px" class="d-flex justify-content-center align-items-center">
      <h3>"Previledge access only to Aliff Franchise."</h3>
    </b-card>
  </div>
  <div class="row" v-else>
    <div class="col-lg-12">
      <b-card class="card-app-design min-height">
        <h1 class="text-center pt-5">Coming soon...</h1>
      </b-card>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BBadge,
  BCardTitle,
  BCardText,
  BAvatar,
  BButton,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import { FILESURL } from "@/config";

export default {
  components: {
    BCard,
    BBadge,
    BButton,
    BAvatar,
    BCardTitle,
    BCardText,
  },
  directives: {
    Ripple,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      FILESURL,
      user_type:null
    };
  },
  computed:{
    isPageVisible() {
      const user_details = this.$store.getters["user/getUserDetails"];
      this.user_type = user_details.user_type
      if (user_details && Array.isArray(user_details.allocated_resources)) {

        const allowcatedResource = user_details.allocated_resources.find((elem) => elem === "community_help")
        if (allowcatedResource) {
          return true
        }
        return false
      }
      return false
  },
  }
};
</script>

<style>
.dashboard__heading {
  color: #002884;
  font-weight: bold;
  margin-bottom: 1.2rem;
}
.student-dashboard .card-title {
  color: #000000;
  font-weight: 600;
}
.primary-color {
  color: #00d09c;
}
.min-height {
  min-height: 60vh;
}
</style>